// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
	@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
	@import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}

.display-block {
	display: block;
}

.truncate p {
	margin-bottom: 0;
	cursor: zoom-in;
}

.pointer {
	cursor: pointer;
}

.contentDash {
	font-size: 50px;
}

.cards-chat {
	height: 80vh;
}

.cards-chat-left {
	height: 80vh;
}
.chat-list {
	height: 55vh;
	overflow-y: scroll;
}

@media screen and (max-width: 768px) {
	.cards-chat-left {
		height: 40vh;
	}
	.chat-list {
		height: 25vh;
		overflow-y: scroll;
	}
}

#toast-container {
	margin-top: 1rem;
}

#toast-container > .toast-info {
	background-color: #06d6a1;
	opacity: 1;
	font-size: medium;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-info:hover {
	background-color: #06d6a1;
	opacity: 1;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.inactive-row td {
	color: red;
}

.alert-danger {
	color: #842029 !important;
	background-color: #f8d7da !important;
	border-color: #f5c2c7 !important;
}

.alert-info {
	color: #0c5460 !important;
	background-color: #d1ecf1 !important;
	border-color: #bee5eb !important;
}

.alert-success {
	color: #0f5132 !important;
	background-color: #d1e7dd !important;
	border-color: #badbcc !important;
}

.title3 {
	font-weight: bold;
	font-size: 28px;
}

.bg-white {
	background-color: white !important;
}

.swal2-container {
	z-index: 20000 !important;
}

//Threads

.reply-sender {
	background: white;
	display: flex;
	justify-content: space-between;
	height: 3.5rem;
	align-items: center;
	gap: 1rem;
	padding: 0.8rem;
	border-radius: 1rem;
	align-self: end;
	margin-bottom: 1rem;
}

.reply-sender > input {
	height: 70%;
	background-color: rgb(236, 236, 236);
	border-radius: 0.5rem;
	border: none;
	outline: none;
	flex: 1;
	font-size: 14px;
	padding: 0px 15px 0px 15px;
}

.reply-sender > div:nth-of-type(1) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}

.reply-sender > div {
	height: 70%;
}

.pointer {
	cursor: pointer;
}

.thread-file {
	width: 100%;
	min-height: 500px;
}

.thread-file-mobile {
	display: none;
}

@media (max-width: 768px) {
	.thread-file {
		display: none;
	}
	.thread-file-mobile {
		display: block;
		width: 25%;
	}
}

.reply {
	display: flex;
}

.reply-button {
	position: absolute;
	right: 11%;
	font-size: small;
}

.edit-reply-button {
	position: absolute;
	right: 7%;
	font-size: small;
}

.delete-reply-button {
	position: absolute;
	right: 3%;
	font-size: small;
}

.new-reply {
	background-color: $primary-light;
}

.nested-replies {
	padding-left: 1.5rem;
	flex-grow: 1;
	border-left: 1px solid gray;
}

.nested-replies-stack {
	display: flex;
}

.reply-stack {
	margin: 0.5rem 0;
}

.reply-stack:last-child {
	margin-bottom: 0;
}

.reply-img-container {
	margin-right: 20px;
}

.reply-img {
	width: 50px;
}
