.Chat {
    position: relative;
    display: grid;
    grid-template-columns: 22% auto;
    gap: 1rem;
  }
  
  .Left-side-chat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .Chat-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    height: auto;
    min-height: 80vh;
  }
  
  .Right-side-chat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .conversation:hover {
    background: #80808038;
    cursor: pointer;
  }
  
  .conversation {
    border-radius: 0.5rem;
    padding: 10px;
    overflow-x: hidden;
  }
  
  .conversation > div {
    position: relative;
  }
  
  .online-dot {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 6px;
  }

  .online-dot-list {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 2.5rem;
    background-color: greenyellow;
  }

  .notification-dot {
    background-color: #06D6A1;
    border-radius: 50%;
    position: absolute;
    right: 0rem;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.7rem;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }
  
  .notification-dot-topbar {
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: 0rem;
    width: 1.2rem;
    height: 1.2rem;
    top: 1rem;
    color: white;
    font-size: smaller;
    font-weight: bold;
    display: flex;
    align-content: center;
    justify-content: center;
  }



  @media screen and (max-width: 990px) {
    .notification-dot-topbar {
      top: 0rem;
    }
  }
  
  .chatbox-empty-message {
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 20px;
  }

  .followerImage {
    width: 40px;
    height: 40px;
    margin-right: 6px;
    border-radius: 50%;
  }
  
  
  @media screen and (max-width: 768px) {
   .Chat {
      grid-template-columns: 100% auto;
      display: block;
    }
  }

  .header-chat {
    width: 100%;
    margin-top: 0.5rem;
  }