.ChatBox-container {
	background: white;
	border-radius: 1rem;
	display: grid;
	grid-template-rows: 7vh 60vh 13vh;
}

.chat-header {
	padding: 1rem 1rem 0rem 1rem;
	display: flex;
	flex-direction: column;
}

.chat-body {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	overflow-y: auto;
	height: 52vh;
	padding: 0px 10px;
	margin-top: 2vh;
}

.message {
	background: #06d6a1;
	color: black;
	padding: 0.7rem;
	border-radius: 1rem 1rem 1rem 0;
	max-width: 28rem;
	width: fit-content;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.message > span:nth-child(2) {
	font-size: 1rem;
	color: black;
	align-self: end;
}

.own {
	align-self: flex-end;
	border-radius: 1rem 1rem 0 1rem;
	background: #c9f7f5;
	color: black;
}

.chat-sender {
	background: white;
	display: flex;
	justify-content: space-between;
	height: 3.5rem;
	align-items: center;
	gap: 1rem;
	padding: 0.8rem;
	border-radius: 1rem;
	align-self: end;
	margin-bottom: 1rem;
}
.chat-sender > input {
	height: 70%;
	background-color: rgb(236, 236, 236);
	border-radius: 0.5rem;
	border: none;
	outline: none;
	flex: 1;
	font-size: 14px;
	padding: 0px 15px 0px 15px;
}
.chat-sender > div:nth-of-type(1) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}
.chat-sender > div {
	height: 70%;
	/*padding: 0px 15px 0px 15px;*/
}

.send-button {
	padding: 15px;
	display: flex;
	align-items: center;
}

.chat-message-image {
	max-width: 250px;
	cursor: zoom-in;
	margin-bottom: 5px;
}

@media screen and (max-width: 650px) {
	.chat-message-image {
		max-width: 100px;
	}
}

@media screen and (max-height: 914px) {
	.chat-body {
		height: 46vh;
	}
}

@media screen and (max-height: 870px) {
	.chat-body {
		height: 41vh;
	}
}

@media screen and (max-height: 660px) {
	.chat-body {
		height: 37vh;
	}
}

@media screen and (max-height: 600px) {
	.chat-body {
		height: 33vh;
	}
}

@media screen and (max-height: 550px) {
	.chat-body {
		height: 26vh;
	}
}

@media screen and (max-height: 490px) {
	.chat-body {
		height: 20vh;
	}
}
